.banner-article {
  background: #F5F5F5;
  padding: 100px 0;
  position: relative; }
  .banner-article__bar {
    position: absolute; }
    .banner-article__bar--left {
      left: 0;
      top: 135px;
      width: 50%; }
      @media (max-width: 767px) {
        .banner-article__bar--left {
          top: 150px; } }
    .banner-article__bar--right {
      bottom: 240px;
      right: 0;
      width: 50%; }
      @media (max-width: 767px) {
        .banner-article__bar--right {
          bottom: 200px; } }
  .banner-article__button {
    background: #DB3645;
    border-color: #DB3645;
    border-radius: 6px;
    border-style: solid;
    color: #FFFFFF;
    cursor: pointer;
    display: block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 14px;
    padding: 8px 5px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: fit-content;
    margin-top: 30px;
    position: relative;
    width: 180px; }
    @media (max-width: 767px) {
      .banner-article__button {
        width: 100%; } }
    .banner-article__button--customizer {
      border-radius: 6px 6px 0 6px; }
      @media (max-width: 767px) {
        .banner-article__button--customizer {
          border-radius: 6px; } }
  .banner-article__button-graphic {
    background: url("../../../images/icon-hand.svg") no-repeat;
    display: block;
    height: 90px;
    left: 160px;
    position: absolute;
    top: 15px;
    width: 220px; }
    @media (max-width: 991px) {
      .banner-article__button-graphic {
        height: 45px;
        top: 23px;
        width: 100px; } }
    @media (max-width: 767px) {
      .banner-article__button-graphic {
        display: none; } }
  .banner-article__button-wrapper {
    margin-top: auto; }
  .banner-article__category {
    color: #1c1417;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .banner-article__category {
        font-size: 10px;
        letter-spacing: 0.83px; } }
  .banner-article__container {
    background: #FFFFFF;
    display: flex;
    min-height: 400px;
    width: 100%; }
    @media (max-width: 767px) {
      .banner-article__container {
        flex-direction: column;
        min-height: 300px; } }
    @media (max-width: 767px) {
      .banner-article__container--mobile-reverse {
        flex-direction: column-reverse; } }
  .banner-article__content {
    display: flex;
    flex-direction: column;
    padding: 45px 80px 45px 45px;
    width: 50%; }
    @media (max-width: 991px) {
      .banner-article__content {
        padding: 40px; } }
    @media (max-width: 767px) {
      .banner-article__content {
        padding: 30px;
        width: 100%; } }
  .banner-article__figure {
    position: relative;
    width: 50%; }
    @media (max-width: 767px) {
      .banner-article__figure {
        width: 100%; } }
    @media (max-width: 767px) {
      .banner-article__figure {
        flex-direction: column;
        height: 300px; } }
    @media (max-width: 575px) {
      .banner-article__figure {
        height: 200px; } }
  .banner-article__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    left: 0;
    position: absolute;
    top: 0; }
  .banner-article__text {
    color: #1c1417;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px; }
    @media (max-width: 767px) {
      .banner-article__text {
        font-size: 14px;
        margin-top: 20px; } }
    .banner-article__text--large {
      font-size: 30px;
      font-weight: 500;
      letter-spacing: -0.75px;
      line-height: 35px; }
      @media (max-width: 767px) {
        .banner-article__text--large {
          font-size: 20px;
          line-height: 25px; } }
  .banner-article__title {
    color: #1c1417;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.75px;
    padding-top: 24px; }
    @media (max-width: 767px) {
      .banner-article__title {
        font-size: 24px;
        line-height: 30px;
        padding-top: 15px; } }
